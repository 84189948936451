import styled from 'styled-components';
import { path } from 'ramda';

export const Content = styled.div`
  height: 100%;

  @media ${path(['theme', 'media', 'tablet'])} {
    height: calc(100% - ${path(['theme', 'header', 'offset'])}px);
    overflow: auto;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
