import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { theme, Header } from '@nn-virtual-pen/ui';
import { Navigation } from '@nn-virtual-pen/education/ui';
import { SupportContext } from '@nn-virtual-pen/education/utils';
import * as Styled from './generic.styled';
import Media from 'react-media';
import {
  TranslationKey,
  useTranslate,
} from '@nn-virtual-pen/education/data-access';

interface GenericLayoutProps {
  withMedia?: boolean;
}

export const GenericLayout: FC<GenericLayoutProps> = ({
  children,
  withMedia,
}) => {
  const { t } = useTranslate();

  const childrenWrapped = withMedia ? (
    <Media
      queries={{
        mobile: `(max-width: ${theme.breakpoints.tablet - 1}px)`,
        tablet: `(min-width: ${theme.breakpoints.tablet}px)`,
        desktop: `(min-width: ${theme.breakpoints.desktop}px)`,
      }}
      defaultMatches={{
        desktop: false,
        tablet: false,
        mobile: false,
      }}
    >
      {children}
    </Media>
  ) : (
    children
  );

  return (
    <>
      <Helmet defaultTitle="Virtual Pen" titleTemplate="%s | Virtual Pen" />
      <Styled.Wrapper>
        <SupportContext>
          <Header>
            <Navigation />
          </Header>
          <Styled.Content>{childrenWrapped}</Styled.Content>
          <CookieConsent
            flipButtons
            enableDeclineButton
            location="bottom"
            buttonText={t(TranslationKey.cookie_accept)}
            declineButtonText={t(TranslationKey.cookie_reject)}
            cookieName="gatsby-gdpr-google-analytics"
            buttonStyle={{
              background: theme.palette.nnBlue,
              color: theme.palette.white,
              border: `1px solid ${theme.palette.nnBlue}`,
              borderRadius: '20px',
              padding: '8px 20px',
            }}
            declineButtonStyle={{
              background: theme.palette.white,
              color: theme.palette.nnBlue,
              border: `1px solid ${theme.palette.nnBlue}`,
              borderRadius: '20px',
              padding: '8px 20px',
            }}
            style={{
              background: theme.palette.white,
              borderTop: `1px solid ${theme.palette.mystic}`,
              color: theme.palette.nnBlue,
            }}
          >
            {t(TranslationKey.cookie_consent)}
          </CookieConsent>
        </SupportContext>
      </Styled.Wrapper>
    </>
  );
};
